@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Ubuntu&display=swap');

html{
  background-image: url('../src/assets/background\ pattern.png');
  background-size: 40%;
  margin-bottom: 200px;
}

#imageModal{
  width: 100vw;
}

.openedImage{
  position: absolute;
  left: 40%;
}


h1{
  font-family: 'Ubuntu', sans-serif;
  letter-spacing: 0.2rem;
}

p{
  font-weight: 400;
  letter-spacing: 0.1rem;
}

a{
  color: #ff6910;
  font-weight: 600;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 8px;
}

::-webkit-scrollbar:horizontal {
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #ff5418;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #3b1363;
}
